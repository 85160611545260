<div class="org-card-grid">
  <ng-content></ng-content>
  <div class="org-card-info-stack">
    <div class="org-card-logo">
      <app-org-logo [org]="orgInfo"></app-org-logo>
    </div>
    <div class="org-card-name-grid">
      <span class="org-card-name">{{ orgInfo.name }}</span>
      <app-org-certified-icon [rmtCertification]="orgInfo.rmtCertification"></app-org-certified-icon>
    </div>
    <button class="btn-reset btn-anchor org-view-profile" (click)="displayRfiProfile()">{{ viewProfileLabel }}</button>
  </div>
</div>
